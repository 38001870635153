import TypeWriter from '../design-system/Typewriter';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-left: 5%;
  @media (max-width: 680px) {
    width: 85%;
    margin-left: 0;
    padding-bottom: 32px;
  }
`;
const Heading = styled.h1`
  color: #363636;
  font-size: 32px;
`;
const Text = styled.p`
  color: #363636;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
`;
const HighlightSpan = styled.span`
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, rgba(247, 209, 200, 0.5) 50%);
  background-size: 200%;
  background-position: 0 0;
  display: inline;
  transition: 0.5s ease;
  &:hover {
    background-position: -100% 0;
    text-decoration: none;
    color: #064554;
  }
`;

const Anchor = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
`;
export default function AboutMeCard(): React.ReactElement {
  return (
    <Container>
      <Heading>
        Hi, I'm{' '}
        <HighlightSpan>
          <TypeWriter>Aiden.</TypeWriter>
        </HighlightSpan>
      </Heading>
      <Text>
        I'm currently a{' '}
        <HighlightSpan>
          <Anchor target="_blank" href="https://openai.com/">
            <b>Member of Technical Staff at OpenAI.</b>
          </Anchor>
        </HighlightSpan>{' '}
        I work on reinforcement learning and alignment for our chat and reasoning models.
        <br />
        <br />I also created the{' '}
        <HighlightSpan>
          <Anchor target="_blank" href="https://aphrodite.global">
            <b>Aphrodite Project</b>
          </Anchor>
        </HighlightSpan>{' '}
        where we have matchmade over{' '}
        <HighlightSpan>
          <Anchor>
            94,195 students and alumni resulting in over 2000 relationships and 12 marriages
          </Anchor>
        </HighlightSpan>{' '}
        from my team's work.
      </Text>
      <Text>
        Previously, I was an{' '}
        <HighlightSpan>
          <Anchor target="_blank" href="https://fellows.kleinerperkins.com/">
            <b>Engineering Fellow with Kleiner Perkins</b>
          </Anchor>
        </HighlightSpan>{' '}
        and Software Engineer at{' '}
        <HighlightSpan>
          <Anchor target="_blank" href="https://www.appliedintuition.com/">
            Applied Intuition
          </Anchor>
        </HighlightSpan>
        , Facebook, and Nvidia DRIVE. I was also a product manager at{' '}
        <HighlightSpan>
          <Anchor target="_blank" href="https://www.open.gov.sg/">
            Open Government Products
          </Anchor>
        </HighlightSpan>{' '}
        where I led a team to create a{' '}
        <HighlightSpan>
          <Anchor
            target="_blank"
            href="https://www.straitstimes.com/singapore/politics/spore-exploring-petition-platform-where-10000-signatures-guarantee-ministry-response"
          >
            <b>prototype national petitions platform for Singapore.</b>
          </Anchor>
        </HighlightSpan>
      </Text>
    </Container>
  );
}

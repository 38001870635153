import aidenPhoto from '../assets/aiden-photo.jpg';
import styled from 'styled-components';

const Card = styled.div`
  background-color: #f4ece6;
  border-radius: 4px;
  box-shadow: 0 7px 30px -10px rgba(0, 0, 0, 0.6);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 375px;
  flex-basis: 2;
`;
const Heading = styled.h1`
  font-size: 32px;
  &:after {
    content: ' ';
    display: block;
    width: 40%;
    margin: 0 auto;
    margin-top: 10px;
    border: 1px solid black;
  }
`;
const Image = styled.img`
  border-radius: 50%;
  overflow: hidden;
  max-width: 50%;
  margin-top: 32px;
`;
const PersonalsContainer = styled.div`
  background-color: white;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  height: 48px;
  margin-top: auto;
  align-items: center;
  gap: 32px; // ensures spacing is consistent
`;

const Text = styled.p`
  color: #3b455a;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
`;

const PERSONALS: IconProps[] = [
  {
    text: 'Linkedin',
    link: 'https://linkedin.com/in/aidenywl',
  },
  {
    text: 'Google Scholar',
    link: 'https://scholar.google.com/citations?hl=en&user=0dTCfIsAAAAJ',
  },
  {
    text: 'Github',
    link: 'https://github.com/aidenywl',
  },
];
export default function Photocard(): React.ReactElement {
  return (
    <Card>
      <Image alt="aiden profile" src={aidenPhoto} />
      <Heading>Aiden Low</Heading>
      <PersonalsContainer>
        {PERSONALS.map((personalData) => (
          <Icon {...personalData} />
        ))}
      </PersonalsContainer>
    </Card>
  );
}

const Anchor = styled.a`
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:not(:first-child)::before {
    content: '';
    position: absolute;
    left: -16px; // positions the separator exactly halfway between items
    height: 40%; // adjust as needed
    width: 1px;
    background-color: #d3d3d3;
    top: 30%; // centers vertically
  }

  &:hover {
    opacity: 0.6;
  }
`;

interface IconProps {
  text: string;
  link: string;
}

function Icon({ text, link }: IconProps): React.ReactElement {
  return (
    <Anchor target="_blank" href={link} rel="noreferrer">
      <Text>{text}</Text>
    </Anchor>
  );
}
